// import React, { useRef, useState, useEffect } from "react";
// import { firestore } from "../firebase/FirebaseConfig";
// import { collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
// import Webcam from "react-webcam";
// import jsQR from "jsqr";
// import "./AdminPage.css";

// const AdminPage = () => {
//   const [password, setPassword] = useState(""); // State for password input
//   const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track if password is correct
//   const [paymentReference, setPaymentReference] = useState("");
//   const [paymentStatus, setPaymentStatus] = useState("");
//   const [scannedQrData, setScannedQrData] = useState(null);
//   const [paymentDetails, setPaymentDetails] = useState(null);
//   const webcamRef = useRef(null);

//   // Handle password verification
//   const handlePasswordSubmit = () => {
//     if (password === "ayoka2025") {
//       setIsAuthenticated(true);
//     } else {
//       alert("Incorrect password!");
//     }
//   };

//   useEffect(() => {
//     if (isAuthenticated) {
//       const interval = setInterval(() => {
//         scanQrCode();
//       }, 500); // Scan every 500ms once authenticated
//       return () => clearInterval(interval);
//     }
//   }, [isAuthenticated]);

//   const scanQrCode = async () => {
//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       if (imageSrc) {
//         const img = document.createElement("img");
//         img.src = imageSrc;

//         img.onload = async () => {
//           const canvas = document.createElement("canvas");
//           const context = canvas.getContext("2d");

//           canvas.width = img.width;
//           canvas.height = img.height;
//           context.drawImage(img, 0, 0, canvas.width, canvas.height);

//           const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
//           const code = jsQR(imageData.data, canvas.width, canvas.height);

//           if (code) {
//             setScannedQrData(code.data);
//             setPaymentReference(code.data);

//             // Verify payment reference
//             try {
//               const q = query(collection(firestore, "tickets"), where("reference", "==", code.data));
//               const querySnapshot = await getDocs(q);

//               if (querySnapshot.empty) {
//                 setPaymentStatus("Ticket reference not found in the database.");
//                 setPaymentDetails(null);
//                 alert("Scan again"); // Show scan prompt
//               } else {
//                 const ticketDoc = querySnapshot.docs[0];
//                 const ticketData = ticketDoc.data();
//                 setPaymentDetails(ticketData);

//                 if (ticketData.verified) {
//                   setPaymentStatus("This ticket has already been used.");
//                   alert("Ticket already verified, scan again"); // Show error message
//                 } else {
//                   // Update document with verified field
//                   await updateDoc(doc(firestore, "tickets", ticketDoc.id), {
//                     verified: true,
//                   });
//                   setPaymentStatus("Ticket verified successfully!");
//                   alert("Scan again"); // Show scan prompt
//                 }
//               }
//             } catch (error) {
//               console.error("Error verifying payment:", error);
//               setPaymentStatus("Error verifying payment.");
//               alert("Scan again"); // Show scan prompt
//             }
//           }
//         };
//       }
//     }
//   };

//   return (
//     <div className="adminPage">
//       {!isAuthenticated ? (
//         <div className="passwordPrompt">
//           <h2>Please enter the admin password</h2>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="Enter password"
//           />
//           <button onClick={handlePasswordSubmit}>Submit</button>
//         </div>
//       ) : (
//         <>
//           <h2>Admin Payment Verification</h2>
//           <div className="scannerContainer">
//             <Webcam
//               ref={webcamRef}
//               screenshotFormat="image/png"
//               videoConstraints={{ facingMode: "environment" }}
//               style={{ width: "100%", height: "300px" }}
//             />
//           </div>

//           {scannedQrData && (
//             <div className="scannedResult">
//               <p>Scanned QR Data:</p>
//               <p>Reference: {scannedQrData}</p>
//             </div>
//           )}

//           {paymentStatus && (
//             <div className={`payment-status ${paymentStatus.includes("not found") ? "error" : "success"}`}>
//               <p>{paymentStatus}</p>
//             </div>
//           )}

//           {paymentDetails && (
//             <div className="payment-details">
//               <h3>Payment Details:</h3>
//               <p><strong>Amount:</strong> {paymentDetails.totalCost}</p>
//               <p><strong>Quantity:</strong> {paymentDetails.quantity}</p>
//               <p><strong>Reference:</strong> {paymentDetails.reference}</p>
//               <p><strong>Status:</strong> {paymentDetails.status}</p>
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// export default AdminPage;


import React, { useRef, useState, useEffect } from "react";
import { firestore } from "../firebase/FirebaseConfig";
import { collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
import Webcam from "react-webcam";
import jsQR from "jsqr";
import "./AdminPage.css";

const AdminPage = () => {
  const [password, setPassword] = useState(""); // State for password input
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track if password is correct
  const [paymentReference, setPaymentReference] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [scannedQrData, setScannedQrData] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const webcamRef = useRef(null);

  // Handle password verification
  const handlePasswordSubmit = () => {
    if (password === "ayoka2025") {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password!");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      const interval = setInterval(() => {
        scanQrCode();
      }, 500); // Scan every 500ms once authenticated
      return () => clearInterval(interval);
    }
  }, [isAuthenticated]);

  const scanQrCode = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const img = document.createElement("img");
        img.src = imageSrc;

        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          canvas.width = img.width;
          canvas.height = img.height;
          context.drawImage(img, 0, 0, canvas.width, canvas.height);

          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, canvas.width, canvas.height);

          if (code) {
            setScannedQrData(code.data);
            setPaymentReference(code.data);

            // Verify payment reference
            try {
              const q = query(collection(firestore, "tickets"), where("reference", "==", code.data));
              const querySnapshot = await getDocs(q);

              if (querySnapshot.empty) {
                setPaymentStatus("Ticket reference not found in the database.");
                setPaymentDetails(null);
                alert("Scan again"); // Show scan prompt
              } else {
                const ticketDoc = querySnapshot.docs[0];
                const ticketData = ticketDoc.data();
                setPaymentDetails(ticketData);

                if (ticketData.verified) {
                  setPaymentStatus("This ticket has already been verified.");
                  alert("Ticket already verified, scan again"); // Show error message
                } else {
                  // Update document with verified field
                  await updateDoc(doc(firestore, "tickets", ticketDoc.id), {
                    verified: true,
                  });
                  setPaymentStatus("Ticket verified successfully!");
                  alert("Scan again"); // Show scan prompt
                }
              }
            } catch (error) {
              console.error("Error verifying payment:", error);
              setPaymentStatus("Error verifying payment.");
              alert("Scan again"); // Show scan prompt
            }
          }
        };
      }
    }
  };

  return (
    <div className="adminPage">
      {!isAuthenticated ? (
        <div className="passwordPrompt">
          <h2>Please enter the admin password</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <button onClick={handlePasswordSubmit}>Submit</button>
        </div>
      ) : (
        <>
          <h2>Admin Payment Verification</h2>
          <div className="scannerContainer">
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/png"
              videoConstraints={{ facingMode: "environment" }}
              style={{ width: "100%", height: "300px" }}
            />
          </div>

          {scannedQrData && (
            <div className="scannedResult">
              <p>Scanned QR Data:</p>
              <p>Reference: {scannedQrData}</p>
            </div>
          )}

          {paymentStatus && (
            <div className={`payment-status ${paymentStatus.includes("not found") ? "error" : "success"}`}>
              <p>{paymentStatus}</p>
            </div>
          )}

          {paymentDetails && (
            <div className="payment-details">
              <h3>Payment Details:</h3>
              <p><strong>Amount:</strong> {paymentDetails.totalCost}</p>
              <p><strong>Quantity:</strong> {paymentDetails.quantity}</p>
              <p><strong>Reference:</strong> {paymentDetails.reference}</p>
              <p><strong>Status:</strong> {paymentDetails.status}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdminPage;
