import React, { useState } from "react";
import ReservationForm from "../components/ReservationForm";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./Home.css";
import Countdown from "react-countdown";
import { Link } from 'react-router-dom';

function Home() {
  const [showReservationForm, setShowReservationForm] = useState(false);

  const toggleReservationForm = () => {
    setShowReservationForm(!showReservationForm);
  };

  const decemberDate = new Date(2024, 11);

  const targetDate = new Date("2024-12-25T00:00:00");

  return (
    <div className="mainContainer">

      <section className="banner">
        <div className="banner-container">

          <div className="banner-text">
            <button onClick={toggleReservationForm} className="reservation-button">
                    Book Reservations
            </button>
          </div>


        <div className="calendar-section">
            <h2>December 2024 Calendar</h2>

              <Calendar
                value={decemberDate}
                defaultView="month"
                minDetail="month"
                maxDetail="month"
                showNavigation={false}
                showNeighboringMonth={false}
                tileContent={({ date, view }) => {
                  if (view === 'month' && date.getMonth() === 11) { // Ensure it's December
                    if (date.getDate() === 25) return <span className="custom-day">W</span>;
                    if (date.getDate() === 26) return <span className="custom-day">T</span>;
                    if (date.getDate() === 27) return <span className="custom-day">F</span>;
                  }
                  return null;
                }}
                tileClassName={({ date, view }) => {
                  if (view === 'month' && date.getMonth() === 11) {
                    if (date.getDate() === 1) {
                      return "remove-background"; // Add custom class for Dec 1st
                    }
                    if ([25, 26, 27].includes(date.getDate())) {
                      return "replace-numbers";
                    }
                  }
                  return null;
                }}
                className="custom-calendar"
              />

              <div className="countdown-timer">
                <h3>Countdown to December 25th</h3>
                <Countdown
                  date={targetDate}
                  renderer={({ days, hours, minutes, seconds }) => (
                    <p>
                      {days}D <span className="colon">: </span>
                      {hours}H <span className="colon">: </span>
                      {minutes}m <span className="colon">: </span>
                      {seconds}s
                    </p>
                  )}
                />
              </div>
        </div>
      </div>
    </section>

    

    <section className="press-release">
    <h2>Press Release</h2>

    <div className="infoContainer">
    <p>
      <strong>Day 1: Kickoff to the Ultimate Red Room Christmas Party!</strong> 🎉  
      Get ready to dive into the energy-packed vibes of our street carnival on Onajimi Street, Gbagada! Access to the carnival is <strong>just 2000 Naira</strong>, and it's worth every naira!  
      Picture this: sizzling grills, mouthwatering street food, and ice-cold drinks while top-notch DJs spin electrifying beats. With hypemen hyping, dancers captivating, and surprise performances lighting up the night, this is more than a party—it's the start of a legendary three-day block party adventure! 💃🏽🕺🏽
    </p>
    <p>
      <strong>Day 2: The Vibe-Box Party—Boxing Day Extravaganza!</strong> 🥊🎶  
      Day 2 brings even more thrills with another incredible street carnival. For <strong>2000 Naira</strong>, you'll experience an explosion of music, energy, and festive cheer. Feast on premium street food, sip refreshing drinks, and lose yourself in the rhythm of the DJs' hottest tracks.  
      The dancers are back to mesmerize, hypemen to energize, and live performances to elevate the vibe. The street will be alive, and you don’t want to miss it! 🌟🔥
    </p>
    <p>
      <strong>Day 3: The Grand Finale—Headlined by T.I Blaze!</strong> 🎤✨  
      The ultimate climax of our block party awaits on Day 3! For just <strong>2000 Naira</strong>, this day is all about epic live performances, featuring T.I Blaze and a lineup of supporting artists who will blow your mind.  
      The streets will be pulsating with music, incredible dancers, delectable food vendors, and non-stop energy. It's the final chance to soak in the festive magic, so make it count.  
      🎵 Let’s wrap up this block party in style and create memories that last a lifetime!
    </p>
  </div>

  </section>


    <section className="newsletter">
      <h2>Stay Updated</h2>
      <p>Subscribe to our newsletter for the latest news and updates.</p>
      <form>
        <input type="email" placeholder="Enter your email" />
        <button type="submit">Subscribe</button>
      </form>
    </section>

    <section className="sponsors">
      <h2>Our Sponsors</h2>
      <div className="sponsor-logos">
      <img src={require("../images/ddarkroom logo.jpeg")} alt="Sponsor 1" className="sponsorsImg" />
      <img src={require("../images/hope autos logo.jpeg")} alt="Sponsor 1" className="sponsorsImg" />
      </div>
    </section>
    {/* Link to Admin */}
    <div className="admin-link-container">
        <Link to="/admin" className="admin-link">Go to Admin Panel</Link>
      </div>
    </div>
  );
}

export default Home;
