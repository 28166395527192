import React, { useState, useRef, useEffect } from 'react';
import { PaystackButton } from 'react-paystack';
import { QRCodeCanvas } from 'qrcode.react';
import { firestore } from './firebase/FirebaseConfig';
import './PaystackPayment.css';
import { collection, addDoc, query, where, getDocs, updateDoc } from 'firebase/firestore';

const PaystackPayment = ({ email, amount, reference, onSuccess, onClose }) => {
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [ticketDetails, setTicketDetails] = useState(null);
  const qrCodeRef = useRef(null);

  const publicKey = 'pk_live_1673dcaca506c25ba39b350602d97e24d21aa98b';
  // const publicKey = 'pk_test_d99b034f79fbadeabfc885bd094e827ece05a76a';

  const handleError = (err) => {
    setError(err);
  };

  const handleSuccess = async (reference) => {
    console.log('Payment successful with reference:', reference); // Add this log for debugging
    try {
      const ticketsCollection = collection(firestore, 'tickets');
      const ticketQuery = query(ticketsCollection, where('reference', '==', reference.reference));
      const querySnapshot = await getDocs(ticketQuery);

      if (!querySnapshot.empty) {
        // Document with the reference found, update it
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, {
          paid: true,
          updatedAt: new Date().toLocaleString(),
        });
        setPaymentStatus('Payment was successful and ticket updated!');
        setTicketDetails(querySnapshot.docs[0].data()); // Set the ticket details to display
      } else {
        // No document found, create a new one
        const ticketData = {
          reference: reference.reference,
          email,
          amount,
          date: new Date().toLocaleString(),
          paid: true, // Mark new tickets as paid
        };

        const docRef = await addDoc(ticketsCollection, ticketData);
        setTicketDetails({ ...ticketData, id: docRef.id }); // Include the document ID in the ticket details
        setPaymentStatus('Payment was successful and new ticket created!');
      }
    } catch (error) {
      setPaymentStatus('Payment failed!');
      console.error('Error processing ticket details:', error);
    }
  };

  const generateQrCodeDataUrl = () => {
    const qrCodeCanvas = qrCodeRef.current?.querySelector('canvas');
    return qrCodeCanvas ? qrCodeCanvas.toDataURL() : null;
  };

  const handleDownload = () => {
    const qrCodeDataUrl = generateQrCodeDataUrl();
    if (qrCodeDataUrl) {
      const link = document.createElement('a');
      link.href = qrCodeDataUrl;
      link.download = `ticket-${ticketDetails?.reference}.png`; // Naming the file
      link.click();
    }
  };

  const componentProps = {
    email,
    amount: amount * 100, // Paystack requires amount in kobo (100 kobo = 1 Naira)
    reference,
    publicKey,
    text: 'Pay Now',
    onSuccess: handleSuccess,
    onClose,
    onError: handleError,
  };

  useEffect(() => {
    if (ticketDetails) {
      console.log('Ticket Details:', ticketDetails); // Log ticket details for debugging
    }
  }, [ticketDetails]); // Log ticket details whenever they change

  return (
    <div>
      {error && (
        <div className="error-popup">
          <p>{`Error: ${error.message || 'An unknown error occurred.'}`}</p>
          <button onClick={() => setError(null)}>Close</button>
        </div>
      )}

      {paymentStatus && <div className="payment-status">{paymentStatus}</div>}

      {/* Conditionally render ticket details and QR code after successful payment */}
      {ticketDetails && (
        <div className="ticket-details">
          <p>Ticket Details:</p>
          <p>Reference: {ticketDetails.reference}</p>
          <p>Quantity: {ticketDetails.quantity}</p>
          <p>Amount: {ticketDetails.totalCost}</p>
           
          <div ref={qrCodeRef}>
            {/* Ensure QR code is generated with the reference number */}
            <QRCodeCanvas value={ticketDetails.reference} size={126} />
          </div>
          {/* Button to download the QR code */}
          <button onClick={handleDownload}>Download Ticket</button>
        </div>
      )}

      {/* Conditionally render the Paystack Button */}
      {!paymentStatus && !ticketDetails && (
        <PaystackButton {...componentProps} className="paystack-button" />
      )}
    </div>
  );
};

export default PaystackPayment;
