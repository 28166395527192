import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import TicketSummary from '../components/TicketSummary';
import ReservationForm from '../components/ReservationForm';
import PaystackPayment from '../PaystackPayment';
import { firestore } from '../firebase/FirebaseConfig';
import './Tickets.css';

function Tickets() {
  const [ticketType, setTicketType] = useState('Street');
  const [quantity, setQuantity] = useState(1);
  const [selectedDate, setSelectedDate] = useState('25');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isEmailPopupVisible, setEmailPopupVisible] = useState(false);
  const [isPaymentPopupVisible, setIsPaymentPopupVisible] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [error, setError] = useState(null);

  const ticketPrices = {
    '25': 2000,
    '26': 2000,
    '27': 2000,
  };

  const generateRandomReference = () => `${Math.floor(100000000 + Math.random() * 900000000)}`;

  const handleTicketTypeChange = (e) => setTicketType(e.target.value);
  const handleQuantityChange = (e) => setQuantity(parseInt(e.target.value, 10));
  const handleDateChange = (e) => setSelectedDate(e.target.value);

  const handleBuyClick = () => {
    if (ticketType === 'VIP') {
      return; // VIP tickets are handled by ReservationForm
    }
    setEmailPopupVisible(true);
  };

  const handleEmailSubmit = async () => {
    try {
      const totalCost = quantity * ticketPrices[selectedDate];
      const reference = generateRandomReference();

      const ticketData = {
        email,
        phoneNumber,
        ticketType: 'Street',
        date: selectedDate,
        quantity,
        totalCost,
        reference,
        createdAt: new Date().toISOString(),
      };

      // Save ticket data to Firestore
      await addDoc(collection(firestore, 'tickets'), ticketData);

      setPaymentDetails({ email, phoneNumber, totalCost, reference });
      setIsPaymentPopupVisible(true);
    } catch (err) {
      console.error('Error saving ticket data:', err);
      alert(`Failed to complete the transaction. Error: ${err.message || err}`);
    } finally {
      setEmailPopupVisible(false);
    }
  };

  const handleReservationComplete = (reservationData) => {
    // console.log('Reservation completed:', reservationData);
    setPaymentDetails(reservationData);
    setIsPaymentPopupVisible(true);
    console.log(reservationData);
  };

  const handlePaymentSuccess = async (response) => {
    try {
      alert(`Payment Successful! Reference: ${response.reference}`);
      setIsPaymentPopupVisible(false);
      setPaymentDetails(null);
    } catch (err) {
      console.error('Error updating payment status:', err);
      alert('Payment update failed.');
    }
  };

  return (
    <div className="tickets">
      <h2>Access Tickets</h2>
      <form>
        <label htmlFor="ticketType">Ticket Type:</label>
        <select id="ticketType" value={ticketType} onChange={handleTicketTypeChange}>
          <option value="Street">Street Experience</option>
          <option value="VIP">VIP Experience</option>
        </select>

        {ticketType === 'Street' && (
          <>
            <label htmlFor="date">Select Date:</label>
            <select id="date" value={selectedDate} onChange={handleDateChange}>
              <option value="25">December 25th</option>
              <option value="26">December 26th</option>
              <option value="27">December 27th</option>
            </select>

            <label htmlFor="quantity">Quantity:</label>
            <select id="quantity" value={quantity} onChange={handleQuantityChange}>
              {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
          </>
        )}
      </form>

      {ticketType === 'VIP' ? (
        <ReservationForm onReservationComplete={handleReservationComplete} />
      ) : (
        <TicketSummary
          ticketType="Street Experience"
          date={selectedDate}
          quantity={quantity}
          price={ticketPrices[selectedDate]}
        />
      )}

      <button
        className="buy-button"
        onClick={handleBuyClick}
        disabled={ticketType === 'Street' && quantity === 0}
      >
        {ticketType === 'VIP' ? 'Submit Reservation' : 'Buy Tickets'}
      </button>

      {isEmailPopupVisible && (
        <div className="email-popup">
          <h3>Enter Your Details</h3>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your phone number"
            required
          />
          <button onClick={handleEmailSubmit}>Submit</button>
        </div>
      )}

      {isPaymentPopupVisible && paymentDetails && (
        <div className="payment-popup">
          <h3>Proceed with Payment</h3>
          <PaystackPayment
            email={paymentDetails.email}
            amount={paymentDetails.totalCost}
            reference={paymentDetails.reference}
            onSuccess={handlePaymentSuccess}
            onClose={() => {
              alert('Transaction was not completed.');
              setIsPaymentPopupVisible(false);
              setPaymentDetails(null);
            }}
          />
        </div>
      )}

      {error && (
        <div className="error-popup">
          <p>{error.message}</p>
          <button onClick={() => setError(null)}>Close</button>
        </div>
      )}
    </div>
  );
}

export default Tickets;



