// import React, { useState } from 'react';
// import { firestore } from '../firebase/FirebaseConfig'; // Import firestore from your firebase.js
// import { getDocs, collection, addDoc} from 'firebase/firestore'; // Import Firestore functions
// import "./ReservationForm.css";

// function ReservationForm({ onReservationComplete }) {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [quantity, setQuantity] = useState('1');

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const totalCost = 2000 * parseInt(quantity, 10);
  
//     try {
//       // Step 1: Get the last reference number from Firestore
//       const ticketsCollection = collection(firestore, 'tickets');
//       const querySnapshot = await getDocs(ticketsCollection);
  
//       let lastReferenceNumber = 0;
  
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         if (data.referenceNumber) {
//           const num = parseInt(data.referenceNumber.replace('VIPwtf2024byjiggyx', ''), 10);
//           if (num > lastReferenceNumber) {
//             lastReferenceNumber = num;
//           }
//         }
//       });
  
//       // Step 2: Generate the next reference number
//       const nextReferenceNumber = `VIPwtf2024byjiggy${String(lastReferenceNumber + 1).padStart(3, '0')}`;
  
//       // Step 3: Create the ticket data
//       const ticketData = {
//         name,
//         email,
//         phone,
//         quantity: parseInt(quantity, 10),
//         totalCost,
//         reference: nextReferenceNumber,
//         timestamp: new Date(),
//       };
  
//       // Step 4: Save the ticket data to Firestore
//       await addDoc(collection(firestore, 'tickets'), ticketData);
  
//       // Step 5: Pass the reservation to the onReservationComplete callback
//       onReservationComplete(ticketData);
  
//       // Step 6: Reset form fields
//       setName('');
//       setEmail('');
//       setPhone('');
//       setQuantity('1');
//     } catch (error) {
//       console.error('Error saving reservation:', error);
//       alert('Failed to save reservation. Please try again.');
//     }
//   };
  

//   return (
//     <form onSubmit={handleSubmit} className="reservation-form">
//       <h3>Reserve Your VIP Spot</h3>
//       <input
//         type="text"
//         placeholder="Your Name"
//         value={name}
//         onChange={(e) => setName(e.target.value)}
//         required
//       />
//       <input
//         type="email"
//         placeholder="Your Email"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//         required
//       />
//       <input
//         type="tel"
//         placeholder="Your Phone Number"
//         value={phone}
//         onChange={(e) => setPhone(e.target.value)}
//         required
//       />
//       <label htmlFor="quantity">Quantity (Number of Tickets):</label>
//       <select
//         id="quantity"
//         value={quantity}
//         onChange={(e) => setQuantity(e.target.value)}  // Update quantity on change
//         required
//       >
//         {Array.from({ length: 15 }, (_, i) => i + 1).map((num) => (
//           <option key={num} value={num}>
//             {num}
//           </option>
//         ))}
//       </select>
//       <button type="submit">Submit Reservation</button>
//     </form>
//   );
// }

// export default ReservationForm;


import React, { useState } from 'react';
import { firestore } from '../firebase/FirebaseConfig'; // Import firestore from your firebase.js
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import "./ReservationForm.css";

function ReservationForm({ onReservationComplete }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [quantity, setQuantity] = useState('1');

  // Function to generate a random reference number
  const generateRandomReference = () => {
    const randomNumber = Math.floor(100000 + Math.random() * 900000); // Generate a 6-digit random number
    return `VIPwtf2024byjiggy${randomNumber}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const totalCost = 2000 * parseInt(quantity, 10);

    try {
      // Generate a random reference number
      const referenceNumber = generateRandomReference();

      // Create the ticket data
      const ticketData = {
        name,
        email,
        phone,
        quantity: parseInt(quantity, 10),
        totalCost,
        reference: referenceNumber,
        timestamp: new Date(),
      };

      // Save the ticket data to Firestore
      await addDoc(collection(firestore, 'tickets'), ticketData);

      // Pass the reservation to the onReservationComplete callback
      onReservationComplete(ticketData);

      // Reset form fields
      setName('');
      setEmail('');
      setPhone('');
      setQuantity('1');
    } catch (error) {
      console.error('Error saving reservation:', error);
      alert('Failed to save reservation. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="reservation-form">
      <h3>Reserve Your VIP Spot</h3>
      <input
        type="text"
        placeholder="Your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input
        type="email"
        placeholder="Your Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="tel"
        placeholder="Your Phone Number"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        required
      />
      <label htmlFor="quantity">Quantity (Number of Tickets):</label>
      <select
        id="quantity"
        value={quantity}
        onChange={(e) => setQuantity(e.target.value)} // Update quantity on change
        required
      >
        {Array.from({ length: 15 }, (_, i) => i + 1).map((num) => (
          <option key={num} value={num}>
            {num}
          </option>
        ))}
      </select>
      <button type="submit">Submit Reservation</button>
    </form>
  );
}

export default ReservationForm;


