import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Logo from "../images/carnival-bg.png"; 

function Navbar() {
  
  const [menuOpen, setMenuOpen] = useState(false);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="WTF Carnival Logo" className="logo-img" />
        </Link>
      </div>
      <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
        
        
      </ul>
      <div className="ticket-button-wrapper">
        {/* Wrap the Buy Tickets button with a Link */}
        <Link to="/tickets">
          <button className="ticket-button">Buy Tickets</button>
        </Link>
      </div>


      
    </nav>
  );
}

export default Navbar;
